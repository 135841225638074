import React, { memo, FC, useState } from 'react';
import { Handle, Position, NodeProps, NodeToolbar, Node } from 'reactflow';
import { BounceLoader } from 'react-spinners';


export interface StartNodeProps extends NodeProps {
  isFetching: boolean;
  onGenerateClick?: (node: Node) => Promise<void>;
}

export interface CustomNodeProps extends NodeProps {
  isFetching: boolean;
  onGenerateClick?: (node: Node) => Promise<void>;
}



const StartNode: FC<StartNodeProps> = ({ data, xPos, yPos, onGenerateClick, isFetching }) => {
  const node = {
    id: 'start',  // Assuming the ID is in data, adjust as needed
    data: data,
    position: { x: xPos, y: yPos },
  };

  const [localIsFetching, setLocalIsFetching] = useState(false);

  return (
    <>
      <NodeToolbar isVisible={true} position={Position.Top} id="toolbar">
        {/* Show loader if this node is being fetched */ }
          {localIsFetching && (
          <div className="loader-container">
            <p>Generating...</p>
            <BounceLoader color={"#fcfcfc"} loading={true} />
          </div>
        )}
        <button onClick={async () => {
          setLocalIsFetching(true);
          await onGenerateClick?.(node);
          setLocalIsFetching(false);
        }}>GENERATE PROBLEMS</button>

        <button className="edit-button">EDIT</button>
      </NodeToolbar>
      <div className="node-header-ori">
        OPPORTUNITIES FOR REDEMPTIVE INNOVATION
      </div>
      <Handle type="source" position={Position.Top} id="a" />
      <div className="node-content">
        <div className="node-title">
          <strong>{data.label}</strong>
        </div>
      </div>
    </>
  );
};

const CustomNode: FC<CustomNodeProps> = ({ id, data, xPos, yPos, onGenerateClick, isFetching }) => {
  const node = {
    id: id,  // The ID of the node
    data: data,
    position: { x: xPos, y: yPos },
  };
  const toolbarId = `toolbar-${id}`;
  const nextPromptType = node.data.next_prompt_type || "explore"; // Fallback to "explore"

  const [localIsFetching, setLocalIsFetching] = useState(false);

  return (
    <>
      <NodeToolbar isVisible={true} position={Position.Top} id={toolbarId}>
        {localIsFetching && (
          <div className="loader-container">
            <p>Generating...</p>
            <BounceLoader color={"#fcfcfc"} loading={true} />
          </div>
        )}
          <button onClick={async () => {
          setLocalIsFetching(true);
          await onGenerateClick?.(node);
          setLocalIsFetching(false);
        }}>GENERATE {nextPromptType.toUpperCase()}</button>
        <button className="edit-button">EDIT</button>
      </NodeToolbar>
      {/* Header section */}
      <div className={`node-header-${data.prompt_type}`}>
        {data.prompt_type.toUpperCase()}
      </div>
      {/* Body section */}
      <Handle type="source" position={Position.Top} id="a" />
      <div className="node-content">
        <div className="node-title">
          {data.label}
        </div>
        <div className="node-description">
          {data.description}
        </div>
      </div>
      <Handle type="target" position={Position.Bottom} id="b" className="node-handle" />
    </>
  );
};

export const CustomNodeMemo = memo(CustomNode);
export const StartNodeMemo = memo(StartNode);
