import Papa from 'papaparse';

export interface ORIData {
  title: string;
  description: string;
}

export const readCSV = async (filePath: string): Promise<ORIData[]> => {
  const response = await fetch(filePath);
  if (!response.body) {
    throw new Error('Response body is null');
  }
  const reader = response.body.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder('utf-8');
  const csv = decoder.decode(result.value);
  const results = Papa.parse(csv, { header: true });
  const data: ORIData[] = results.data as any;
  
  return data;
};
