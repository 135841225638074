import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ORIScreen } from '../ORI/ORIScreen';
import { TextFieldScreen } from '../Input/TextFieldScreen';
import Flow from '../Flow/FlowScreen';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import './App.css';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const App = () => {
  return (
    <ThemeProvider theme={darkTheme}>
    <div className="App">
      <CssBaseline />
      <header className="App-header">
        <img src="/trailhead-logo-32x32.png" alt="Trailhead Logo" />&nbsp;&nbsp;Trailhead - Find your redemptive quest
      </header>
      <Router>
        <Routes>
          <Route path="/" element={<ORIScreen />} />
          <Route path="/input-text" element={<TextFieldScreen />} />
          <Route path="/flow" element={<Flow />} />
        </Routes>
      </Router>
    </div>
    </ThemeProvider>
  );
};

export default App;
