import { useCallback, useState, useMemo, useEffect } from 'react';
import ReactFlow, { addEdge, useNodesState, useEdgesState, Node, Edge, Connection, Controls, ControlButton, MiniMap, useReactFlow, ReactFlowProvider, NodeProps } from 'reactflow';
import Alert from '@mui/material/Alert';
import { StartNodeProps, StartNodeMemo, CustomNodeMemo, CustomNodeProps } from './CustomNode';
import { useLocation } from 'react-router-dom';
import { ORIData } from '../ORI/ORIScreen';  // Replace with your actual import

import 'reactflow/dist/style.css';
import './Flow.css';

interface YourLocationStateType {
  selectedORIs: ORIData[];
  interests: string;
  problems: string;
  futures: string;
}
interface NodeObject {
  title: string;
  body: string;
  prompt_type: string;
  next_prompt_type?: string;
}

interface CustomNode extends Node {
  metadata?: {
    type?: string;
  };
}

// Explicitly specify types for initialNodes and initialEdges
const initialNodes: Node[] = [
  {
    id: 'start',
    type: 'startNode',
    data: { label: 'ORI', description: 'Opportunity for Redemptive Innovation', prompt_type: 'ori' },
    position: { x: 450, y: 800 },
  },
];

const initialEdges: Edge[] = [];

function Flow() {
  const { fitView } = useReactFlow();  
  const [focusIds, setFocusIds] = useState<string[]>([]);
  const [apiError, setApiError] = useState('');

  const location = useLocation();
  const { selectedORIs, interests, problems, futures } = (location.state as YourLocationStateType);
  
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [currentNode, setCurrentNode] = useState<Node | null>(null); 


  useEffect(() => {
    const newLabel = selectedORIs.map(ori => ori.title).join(' + ');  
    const updatedNodes = nodes.map(node => {
      if (node.id === 'start') {
        return { ...node, data: { ...node.data, label: newLabel } };
      }
      return node;
    });
    setNodes(updatedNodes);
  }, [selectedORIs, setNodes]);

  const onConnect = useCallback(
    (params: Connection | Edge) => setEdges((eds: Edge[]) => addEdge(params, eds)),
    [setEdges]
  );

  const generateNodes = useCallback(async (node: CustomNode, selectedORIs: ORIData[], interests: string, problems: string, futures: string) => {
    // Define the API URL based on the environment
    const apiUrl =
      process.env.NODE_ENV === 'production'
        ? 'https://trailhead-back.sproutai.com/generate'
        : 'http://127.0.0.1:5000/generate';
        
    let response;
    if (process.env.NODE_ENV === 'development') {
      // Temporarily stub the API call and populate dummy data on the client side
      const randomType = ['ori', 'problem', 'vision', 'idea', 'evaluation', 'critique'][Math.floor(Math.random() * 6)];
      response = { ok: true, json: () => ({ message: 'Data generated', nodes: [
        {
            "body": "The sheer volume and velocity of technological innovations are inundating individuals with gadgets and applications that promise efficiency but often lead to cognitive overload and decision paralysis. This constant state of being 'plugged in' erodes attention spans, disrupts work-life balance, and contributes to stress and anxiety, undermining mental wellness.",
            "next_prompt_type": "vision",
            "prompt_type": randomType,
            "title": "Overwhelmed by Technology"
        },
        {
            "body": "A significant portion of the population lacks the necessary digital literacy to manage and use technology in a way that promotes mental health. Many are unaware of how their online habits can exacerbate stress, loneliness, and depression, and there is an absence of accessible tools or guidance to help them navigate the digital landscape for healthier living.",
            "next_prompt_type": "vision",
            "prompt_type": randomType,
            "title": "Inadequate Digital Literacy for Mental Wellbeing"
        },
        {
            "body": "Modern technology has the paradoxical effect of simultaneously connecting and isolating individuals. Social media and messaging platforms may simulate a sense of community but can also lead to real-world social isolation, increased anxiety, and diminished self-worth from constant comparison, all contributing to the mental health crisis.",
            "next_prompt_type": "vision",
            "prompt_type": randomType,
            "title": "Tech-Enabled Isolation and Anxiety"
        }
      ] 
      }) };
    } else {
      // Make the actual API call
      response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          selected_oris: selectedORIs,
          interests: interests,
          problems: problems,
          futures: futures,
          selected_node: {
            label: node.data?.label,
            description: node.data?.description,
            prompt_type: node.data?.prompt_type || 'ori',
          },
        }),
      });
    }

    console.log('NODE_ENV:', process.env.NODE_ENV);
    console.log('response:', response);

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    // Check if the nodes array is empty and handle accordingly
    if (data.message === 'Data generated' && data.nodes.length === 0) {
      setApiError('Data was generated but no nodes were returned. Please try again.');
      return;
    }

    const totalNodes = data.nodes.length;
    const spacing = 300; // Horizontal spacing between nodes
    const totalWidth = totalNodes * spacing;
    const startX = node.position.x - totalWidth / 2 + spacing / 2;

    const newNodes = data.nodes.map((nodeObj: NodeObject, index: number) => ({
      id: `${node.id}-${Math.random().toString(36).substr(2, 9)}`,
      data: {
        label: nodeObj.title,
        description: nodeObj.body,
        prompt_type: nodeObj.prompt_type,
        next_prompt_type: nodeObj.next_prompt_type, // new field
      },
      type: 'customNode',
      position: { x: startX + index * spacing, y: node.position.y - 500 },
    }));

    // Hide node toolbar after API response
    //const toolbarId = `toolbar-${node.id}`;
    //const toolbar = document.getElementById(toolbarId);
    //if (toolbar) toolbar.style.display = 'none';

    // Creating new edges
    const newEdges = newNodes.map((newNode: Node, index: number) => ({
      id: `${node.id}-${newNode.id}`, // Simplified ID
      source: node.id,
      target: newNode.id,
      sourceHandle: 'a',
      targetHandle: 'b',
    }));

    console.log('New Nodes: ', newNodes);
    console.log('New Edges: ', newEdges);

    setNodes((prevNodes) => {
      const newNodesList = [...prevNodes, ...newNodes];
      console.log('New State for Nodes: ', newNodesList);
      return newNodesList;
    });

    setEdges((prevEdges) => {
      const newEdgesList = [...prevEdges, ...newEdges];
      console.log('New State for Edges: ', newEdgesList);
      return newEdgesList;
    });

    setFocusIds(newNodes.map((node: Node) => node.id));
  }, [setNodes, setEdges, setApiError]);



  const nodeTypes = useMemo(() => {
    return {
      customNode: ((props: CustomNodeProps & NodeProps) => (
        <CustomNodeMemo
          {...props}
          onGenerateClick={async (node) => {
            setCurrentNode(node);
            await generateNodes(node, selectedORIs, interests, problems, futures);
          }}
        />
      )) as React.ComponentType<NodeProps>,  // Type cast here
      startNode: ((props: StartNodeProps & NodeProps) => (
        <StartNodeMemo
          {...props}
          onGenerateClick={async (node) => {
            setCurrentNode(node);
            await generateNodes(node, selectedORIs, interests, problems, futures);
          }}
        />
      )) as React.ComponentType<NodeProps>,  // Type cast here
    };
  }, [generateNodes, selectedORIs, interests, problems, futures]);
  
  
  
  

  // Focus on the new nodes after they are generated
  useEffect(() => {
    if (focusIds.length > 0) {
      setTimeout(() => {
        fitView({ nodes: focusIds.map(id => ({ id })), duration: 500 });
      }, 500);  // delay
      setFocusIds([]);
    }
  }, [focusIds, fitView]);
  

  const customOnNodesChange = (changes: any) => {
    onNodesChange(changes);
    changes.forEach((change: any) => {
      if (
        change.type === "dimensions" &&
        focusIds.includes(change.id) && // check if focusIds array includes this node
        change.dimensions &&
        change.dimensions.height > 0 &&
        change.dimensions.width > 0
      ) {
        if (focusIds.length > 0) {  // Check if there are IDs to focus on
          console.log("About to call fitView with nodes: ", focusIds.map(id => ({ id })));
          fitView({ nodes: focusIds.map(id => ({ id })), duration: 500 });
        }        
        setFocusIds([]);  // Reset focusIds
      }
    });
  };
  
  
  const proOptions = { hideAttribution: true };

  return (
    <div className="Flow" style={{ height: '95vh', width: '100%' }}>
            {/* Display error message if there is an error */}
            {apiError && <Alert severity="error">{apiError}</Alert>}

      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={customOnNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        fitView={false}
        nodeTypes={nodeTypes}
        proOptions={proOptions}
        attributionPosition="bottom-center"
      >
        <Controls>          
        </Controls>
        <MiniMap zoomable pannable />
        
      </ReactFlow>
      
    </div>
  );
}

// Wrapping with ReactFlowProvider is done outside of the component
function FlowWithProvider(props: any) {
  return (
    <ReactFlowProvider>
      <Flow {...props} />
    </ReactFlowProvider>
  );
}

export default FlowWithProvider;
