import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import { Container } from '@mui/material';
import '../App/App.css';
import { readCSV } from './readCSV';

export interface ORIData {
  title: string;
  description: string;
}

export const ORIScreen: React.FC = () => {
  const [expanded, setExpanded] = useState<number | null>(null);
  const [selectedCards, setSelectedCards] = useState<number[]>([]);
  const [oriData, setOriData] = useState<ORIData[]>([]);
  const navigate = useNavigate();

  const [selectedORIs, setSelectedORIs] = useState<string[]>([]);
  const [interests, setInterests] = useState<string>('');
  const [problems, setProblems] = useState<string>('');
  const [futures, setFutures] = useState<string>(''); 

  // Load initial data and state from session storage
  useEffect(() => {
    const fetchData = async () => {
      const data = await readCSV('./ori.csv');
      setOriData(data);
    };

    const loadSessionData = () => {
      const savedSelectedCards = sessionStorage.getItem('selectedCards');
      const savedSelectedORIs = sessionStorage.getItem('selectedORIs');
      const savedInterests = sessionStorage.getItem('interests');
      const savedProblems = sessionStorage.getItem('problems');
      const savedFutures = sessionStorage.getItem('futures');

      if (savedSelectedCards) {
        setSelectedCards(JSON.parse(savedSelectedCards));
      }
      if (savedSelectedORIs) {
        setSelectedORIs(JSON.parse(savedSelectedORIs));
      }
      if (savedInterests) {
        setInterests(savedInterests);
      }
      if (savedProblems) {
        setProblems(savedProblems);
      }
      if (savedFutures) {
        setFutures(savedFutures);
      }
    };

    fetchData();
    loadSessionData();
  }, []);

  // Save state to session storage whenever it changes
  useEffect(() => {
    sessionStorage.setItem('selectedCards', JSON.stringify(selectedCards));
    sessionStorage.setItem('selectedORIs', JSON.stringify(selectedORIs));
    sessionStorage.setItem('interests', interests);
    sessionStorage.setItem('problems', problems);
    sessionStorage.setItem('futures', futures);
  }, [selectedCards, selectedORIs, interests, problems, futures]);

  const handleExpandClick = (index: number) => {
    setExpanded(expanded === index ? null : index);
  };

  const handleCardClick = (index: number) => {
    setSelectedCards((prevSelectedCards) => {
      const isAlreadySelected = prevSelectedCards.includes(index);
      return isAlreadySelected
        ? prevSelectedCards.filter((i) => i !== index)
        : [...prevSelectedCards, index].slice(0, 2); // Allow only up to 2 cards
    });
  };

  const handleNextClick = () => {
    const selectedORIData = selectedCards.map((index) => oriData[index]);
    navigate('/input-text', { state: { selectedORIs: selectedORIData } });
  };


  return (
    <>
    <Container maxWidth="md" className="top-container">
      <Card elevation={3} className="top-card">
        <CardContent className="top-card__content">
          {selectedCards.length === 0 ? (
            <Typography variant="h4" className="top-card__title1">
              Select one or two Opportunities for Redemptive Innovation (ORI)
            </Typography>
          ) : (
            <div>
              <Typography variant="h4" className="top-card__title2">
                Selected Cards:
              </Typography>
              <Typography component={'span'} variant="body1" className="top-card__body">
                <ol>
                  {selectedCards.map((index) => (
                    <li key={index}>{oriData[index]?.title}</li>
                  ))}
                </ol>
              </Typography>
              <Button
                className="button-main"
                variant="contained" 
                color="primary" 
                disabled={selectedCards.length === 0} 
                onClick={handleNextClick}
              >Next</Button>
            </div>
          )}
        </CardContent>
      </Card>
      </Container>
      <div className="ori-container" style={{ display: 'flex', flexWrap: 'wrap', marginTop: '20px', justifyContent: 'center' }}>
        {oriData.map((ori, index) => (
          <Card className="ori-card"
            sx={{
              width: 280,
              marginBottom: 2,
              marginRight: 2,
              backgroundColor: selectedCards.includes(index) ? 'var(--brand)' : 'var(--shade)',
              color: selectedCards.includes(index) ? 'var(--base)' : '#52d4a987',
            }}
            key={index}
            onClick={() => handleCardClick(index)}
          >
            <CardHeader title={ori.title} className="ori-card__title"/>
            <CardActions disableSpacing className="ori-card__icon">
              <IconButton className={selectedCards.includes(index) ? 'ori-card__icon ori-card__icon-onclick' : 'ori-card__icon ori-card__icon-default'}
                onClick={(e) => {
                  e.stopPropagation();
                  handleExpandClick(index);
                }}
                aria-expanded={expanded === index}
                aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded === index} timeout="auto" unmountOnExit>
              <CardContent className="ori-card__wrapper">
                <Typography className="ori-card__description" paragraph>{ori.description}</Typography>
              </CardContent>
            </Collapse>
          </Card>
        ))}
      </div>
      <div className="footer-container">
        <Button className="footer-button button-main" variant="contained" color="primary" disabled={selectedCards.length === 0} onClick={handleNextClick}>
          Next
        </Button>
      </div>
    </>
  );
};
