import React, { useState } from 'react';
import { TextareaAutosize, Button } from '@mui/material';
import { styled, Container, Card, CardContent, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const StyledTextarea = styled(TextareaAutosize)`
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  margin-bottom: 15px;
`;

interface ORIData {
  title: string;
  description: string;
}

interface LocationState {
  selectedORIs: ORIData[];
}

export const TextFieldScreen: React.FC = () => {
  const location = useLocation();
  const selectedORIs: ORIData[] = (location.state as LocationState)?.selectedORIs || [];
  const [interests, setInterests] = useState<string>("");
  const [problems, setProblems] = useState<string>("");
  const [futures, setFutures] = useState<string>("");
  const navigate = useNavigate();

  const handleNextClick = () => {
    navigate('/Flow', { state: { selectedORIs, interests, problems } });
  };

  return (
    <Container maxWidth="md" className="top-container">
      <Card elevation={3} className="top-card">
        <CardContent className="top-card__content">
          <Typography variant="h4" className="top-card__title1">
            Next, think about your selected ORI(s) and answer the 3 questions below: [optional]
          </Typography>
          <Typography component={'p'} variant="body1" className="top-card__body">
          <ol>
              {selectedORIs.map((ori, index) => (
                <li key={index} style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{ori.title}</li>
              ))}
          </ol>
          </Typography>
          <Typography variant="body1" paragraph>
            What specifically interests you about the above, what are your related passions?
          </Typography>
          <StyledTextarea
            aria-label="minimum height"
            minRows={3}
            placeholder=""
            value={interests}
            onChange={(e) => setInterests(e.target.value)}
          />
          <Typography variant="body1" paragraph>
            What is broken, what is exploitative, and what needs to be redeemed?
          </Typography>
          <StyledTextarea
            aria-label="minimum height"
            minRows={3}
            placeholder=""
            value={problems}
            onChange={(e) => setProblems(e.target.value)}
          />
          <Typography variant="body1" paragraph>
            What could a redemptive future look like here in 10-30 years? (conceptually and societally)
          </Typography>
          <StyledTextarea
            aria-label="minimum height"
            minRows={3}
            placeholder=""
            value={futures}
            onChange={(e) => setFutures(e.target.value)}
          />
          <Button className="button-main" variant="contained" color="primary" onClick={handleNextClick}>
            Next
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};
